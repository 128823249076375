import React from 'react';
import { graphql } from 'gatsby';
import SolutionLayout from 'components/pages/SolutionPage';

const seo = {
  title:
    'Q°emotion - Find the right insights with emotional data, increase NPS and conversion rate',
  keywords: [
    'Data Analysis',
    'Data visualisation',
    'Data management',
    'Data',
    'Segmentation',
    'Modelling',
    'Automatic data processing',
    'Reportings',
    'Emotions',
  ],
  description:
    'Discover in an instant the emotions felt on your customer journeys and their impact on your NPS in order to improve your conversion rate',
};

export default props => <SolutionLayout {...props} seo={seo} />;

export const pageQuery = graphql`
  query {
    dataBlockSubintroTitle: markdownRemark(
      frontmatter: {
        pageType: { eq: "solutionEtude" }
        blockType: { eq: "subintroBlock" }
        lang: { eq: "en" }
        component: { eq: "title" }
      }
    ) {
      rawMarkdownBody
      frontmatter {
        subTitle
      }
    }
    dataBlockSubintroBody: markdownRemark(
      frontmatter: {
        pageType: { eq: "solutionEtude" }
        blockType: { eq: "subintroBlock" }
        lang: { eq: "en" }
        component: { eq: "block" }
      }
    ) {
      rawMarkdownBody
    }
    imageSubintroBlock: allFile(
      filter: {
        relativeDirectory: { eq: "bubbleImages" }
        base: { in: ["girlOrange.png", "dataGlobal_en.png"] }
      }
      sort: { fields: [base], order: [DESC] }
    ) {
      nodes {
        base
        childImageSharp {
          fluid(maxWidth: 550, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
            presentationWidth
          }
        }
      }
    }
    dataObjectives: allMarkdownRemark(
      filter: {
        frontmatter: {
          pageType: { eq: "solutionEtude" }
          blockType: { eq: "subintroBlock" }
          lang: { eq: "en" }
          component: { eq: "objectives" }
        }
      }
      sort: { fields: [frontmatter___blockNumber] }
    ) {
      nodes {
        rawMarkdownBody
        frontmatter {
          relatedImage
          blockNumber
        }
      }
    }
    imageObjectives: allFile(
      filter: {
        relativeDirectory: { eq: "indexPage/subIntroBlock/blockImage/minImage" }
      }
      sort: { fields: [base] }
    ) {
      nodes {
        base
        childImageSharp {
          fluid(maxWidth: 70, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
            presentationWidth
          }
        }
      }
    }
    imageExplanationsBlock: file(
      relativeDirectory: { eq: "solutionPage/nps/explanationsBlock/en" }
    ) {
      base
      childImageSharp {
        fluid(maxWidth: 500, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
    dataExplanationsBlockTitle: markdownRemark(
      frontmatter: {
        pageType: { eq: "solutionEtude" }
        blockType: { eq: "explanationsBlock" }
        lang: { eq: "en" }
        component: { eq: "title" }
      }
    ) {
      rawMarkdownBody
    }
    dataExplanationsBlockSubtitle: markdownRemark(
      frontmatter: {
        pageType: { eq: "solutionEtude" }
        blockType: { eq: "explanationsBlock" }
        lang: { eq: "en" }
        component: { eq: "subtitle" }
      }
    ) {
      rawMarkdownBody
      frontmatter {
        author
      }
    }
    dataExplanationsBlockContent: markdownRemark(
      frontmatter: {
        pageType: { eq: "solutionEtude" }
        blockType: { eq: "explanationsBlock" }
        lang: { eq: "en" }
        component: { eq: "content" }
      }
    ) {
      rawMarkdownBody
      frontmatter {
        subTitle
      }
    }
    dataBlockOperationTitle: markdownRemark(
      frontmatter: {
        pageType: { eq: "indexPage" }
        blockType: { eq: "operationBlock" }
        lang: { eq: "en" }
        component: { eq: "title" }
      }
    ) {
      rawMarkdownBody
    }
    dataBlockOperationBody: allMarkdownRemark(
      filter: {
        frontmatter: {
          pageType: { eq: "indexPage" }
          blockType: { eq: "operationBlock" }
          lang: { eq: "en" }
          component: { eq: "subTitle" }
        }
      }
      sort: { fields: [frontmatter___numBlockOperation] }
    ) {
      nodes {
        rawMarkdownBody
        frontmatter {
          numBlockOperation
          relatedImage
        }
      }
    }
    imageOperationBlock: allFile(
      filter: { relativeDirectory: { eq: "indexPage/operationBlock/en" } }
    ) {
      nodes {
        base
        childImageSharp {
          fluid(maxWidth: 300, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
            presentationWidth
          }
        }
      }
    }
    imageEmotionsBlock: allFile(
      filter: { relativeDirectory: { eq: "solutionPage/emotionsBlock" } }
    ) {
      nodes {
        base
        extension
        publicURL
        childImageSharp {
          fluid(maxWidth: 100, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
            presentationWidth
          }
        }
      }
    }
    dataBlockEmotionsTitle: markdownRemark(
      frontmatter: {
        pageType: { eq: "solutionMarketing" }
        blockType: { eq: "emotionsBlock" }
        lang: { eq: "en" }
        component: { eq: "title" }
      }
    ) {
      rawMarkdownBody
    }
    dataTestimonialBlock: allMarkdownRemark(
      filter: {
        frontmatter: {
          pageType: { eq: "solutionMarketing" }
          blockType: { eq: "testimonialBlock" }
          lang: { eq: "en" }
        }
      }
      sort: { order: ASC, fields: [frontmatter___order] }
    ) {
      nodes {
        rawMarkdownBody
        frontmatter {
          author
          role
          image
          youtubeID
          youtubeTitle
        }
      }
    }
    imageTestimonialBlock: allFile(
      filter: {
        relativeDirectory: { eq: "bubbleImages" }
        base: { in: ["manPinkLaugh.png", "girlBlue.png"] }
      }
      sort: { fields: [id], order: [ASC] }
    ) {
      nodes {
        base
        childImageSharp {
          fluid(maxWidth: 550, quality: 100) {
            presentationWidth
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
    imagesQuotesTestimonialBlock: allFile(
      filter: {
        relativeDirectory: {
          eq: "solutionPage/analyseVerbatim/testimonialBlock"
        }
      }
    ) {
      nodes {
        base
        childImageSharp {
          fluid(maxWidth: 300, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
            presentationWidth
          }
        }
      }
    }
    dataBlockSliderTitle: markdownRemark(
      frontmatter: {
        pageType: { eq: "solutionEtude" }
        blockType: { eq: "sliderBlock" }
        lang: { eq: "en" }
        component: { eq: "title" }
      }
    ) {
      rawMarkdownBody
    }
    dataBlockSliderBody: allMarkdownRemark(
      filter: {
        frontmatter: {
          pageType: { eq: "solutionEtude" }
          blockType: { eq: "sliderBlock" }
          lang: { eq: "en" }
          component: { eq: "slide" }
        }
      }
      sort: { fields: [frontmatter___numSlide] }
    ) {
      nodes {
        rawMarkdownBody
        frontmatter {
          numSlide
          title
          subTitle
          relatedImage
        }
      }
    }
    imageSliderBlock: allFile(
      filter: { relativeDirectory: { eq: "solutionPage/nps/sliderBlock/en" } }
    ) {
      nodes {
        base
        childImageSharp {
          fluid(maxWidth: 650, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
            presentationWidth
          }
        }
      }
    }
    dataBlockLinkBody: allMarkdownRemark(
      filter: {
        frontmatter: {
          pageType: { eq: "solutionEtude" }
          blockType: { eq: "linkBlock" }
          lang: { eq: "en" }
        }
      }
      sort: { fields: [frontmatter___idBlock] }
    ) {
      nodes {
        rawMarkdownBody
        frontmatter {
          subTitle
          color
          link
          buttonText
        }
      }
    }
  }
`;
